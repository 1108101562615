<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="is-pulled-right">
            <b-field grouped>
              <b-switch v-model="params.archived">
                Arhiveeritud
              </b-switch>
              <b-field>
                <b-radio-button
                  v-model="provider"
                  native-value="gp"
                  :disabled="disabled"
                  :loading="loading"
                  type="is-success">
                  <span>geopeitus.ee</span>
                </b-radio-button>
                <b-radio-button
                  v-model="provider"
                  native-value="gc"
                  :disabled="disabled"
                  :loading="loading"
                  type="is-info">
                  <span>geocaching.com</span>
                </b-radio-button>
                <b-radio-button
                  v-model="provider"
                  native-value=""
                  :disabled="disabled"
                  :loading="loading"
                  type="is-danger">
                  <span>gp+gc</span>
                </b-radio-button>
              </b-field>
              <b-button type="is-primary"
                icon-left="download"
                :disabled="disabled"
                :loading="loading"
                @click="load()">
                Jooksuta
              </b-button>
            </b-field>
          </div>
          <h1 class="title">
            Eesti aarded
          </h1>
          <h2 class="subtitle">
            GP / GC Eesti aarded
          </h2>
        </div>
      </div>
    </section>
    <section v-if="data.length">
      <div class="container compare-table">
        <section class="modal-card-body">
          <h3 class="subtitle mt-5">
            Aarete arv ({{ data.length }})
          </h3>
          <b-table
            :data="data"
            sortable
            bordered
            narrowed
            hoverable
            paginated
            per-page="50"
            default-sort="code"
            :mobile-cards="true">
            <template v-for="col in columns">
              <b-table-column :key="col.field" v-bind="col" cell-class="cell">
                <template v-slot="props">
                  <common-list-cell
                    :col="col"
                    :row="props.row">
                  </common-list-cell>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </section>
      </div>
    </section>
  </div>
</template>

<script>

import apiService from '@/services/ApiService'
import { error } from '@/utils'
import CommonListCell from '@/components/CommonListCell.vue'

export default {
  name: 'CachesList',
  components: {
    CommonListCell
  },
  data() {
    let data = JSON.parse(localStorage.getItem('user'))
    const providers = []
    if (data && data['gp']) {
      providers.push('gp')
    }
    if (data && data['gc']) {
      providers.push('gc')
    }
    return {
      providers: providers,
      provider: '',
      disabled: providers.length === 0,
      loading: false,
      data: [],
      columns: [
        {
          field: 'code',
          label: 'Kood',
          searchable: true,
          sortable: true,
          width: 60
        },
        {
          field: 'name',
          label: 'Nimi',
          searchable: true,
          sortable: true
        },
        {
          field: 'type',
          label: 'Tüüp',
          searchable: true,
          width: 140
        },
        {
          field: 'time',
          label: 'Peidetud',
          sortable: true,
          width: 115
        },
        {
          field: 'coordinates',
          label: 'Koordinaadid',
        },
        {
          field: 'container',
          label: 'Suurus',
          searchable: true,
          sortable: true,
          width: 60
        },
        {
          field: 'difficulty',
          label: 'Raskus',
          numeric: true,
          sortable: true,
          width: 60
        },
        {
          field: 'terrain',
          label: 'Maastik',
          numeric: true,
          sortable: true,
          width: 60
        },
        {
          field: 'status',
          label: 'Staatus',
          searchable: true,
          sortable: true,
          width: 100
        },
        {
          field: 'provider',
          label: 'Allikas',
          width: 60
        }
      ],
      params: {
        active: true,
        disabled: true,
        archived: false
      }
    }
  },
  methods: {
    load () {
      this.disabled = true
      this.loading = true

      apiService.loadCaches(this.provider, this.params)
        .then(response => {
          if (response.ok) {
            this.data = response.data
          }
        })
        .catch(error)
        .finally(() => {
          this.disabled = false
          this.loading = false
        })
    },
    fieldNames() {
      return this.columns.filter(col => this.fields.includes(col.field)).map(col => col.label)
    }
  }
}
</script>

<style>

.compare-table table {
  height: 1px;
}
.compare-table table tr {
  height: 100%;
}
.compare-table table tr td.cell {
  height: 100%;
  padding: 0;
}
.compare-table table tr td.cell > div {
  height: 100%;
  padding: 0.25em 0.5em;
}
.compare-table table tr.detail td.cell > div.error {
  filter: brightness(95%)
}

</style>
